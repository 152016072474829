<template>
  <!--
    Used to add CutConfigs that are used in the videoPostprocessing themes.
  -->
  <div class="cutConfigListItemAdd">
    <form>
      <h4 class="m-0 mt-3">
        {{ $t('cutConfig') }}
      </h4>
      <hr class="m-0 mb-3">

      <div class="form-group m-form__group row mb-2">
        <label class="col-4 col-form-label">{{ $t('type') }}</label>
        <div class="col-8">
          <select
            v-model="cutConfig.configType"
            :class="['form-control', { 'is-invalid': this.$validator.errors.has('ConfigType') }]"
          >
            <option
              v-for="(key, index) in configTypes"
              :key="`cutConfigListItemAdd_Option${ index }`"
              :value="key"
            >
              {{ $t(`videoPostProcessing.cutConfigType.${ toCamelCase(key) }`) }}
            </option>
          </select>
          <span
            v-show="errors.has('ConfigType')"
            class="badge badge-danger"
          >{{ errors.first('ConfigType') }}</span>
        </div>
      </div>

      <div class="form-group m-form__group row mb-2">
        <label class="col-4 col-form-label">{{ $t('name') }}</label>
        <div class="col-8">
          <input
            v-model="cutConfig.name"
            v-focus
            :class="['form-control', { 'is-invalid': this.$validator.errors.has('Name') }]"
            type="text"
          >
          <span
            v-show="errors.has('Name')"
            class="badge badge-danger"
          >{{ errors.first('Name') }}</span>
        </div>
      </div>

      <div class="form-group m-form__group row mb-2">
        <label class="col-4 col-form-label">{{ $t('sourceName') }}</label>
        <div class="col-8">
          <select
            v-model="cutConfig.sourceName"
            :class="['form-control', { 'is-invalid': this.$validator.errors.has('SourceName') }]"
          >
            <option
              v-for="(name, index) in sourceNames"
              :key="`cutConfigListItemAdd_Option${ index }`"
              :value="name"
            >
              {{ name }}
            </option>
          </select>
          <span
            v-show="errors.has('SourceName')"
            class="badge badge-danger"
          >{{ errors.first('SourceName') }}</span>
        </div>
      </div>

      <h5 class="m-0 mt-4">
        {{ $t('start') }}
      </h5>
      <hr class="m-0 mb-3">

      <div class="form-group m-form__group row mb-2">
        <label class="col-4 col-form-label">{{ $t('cutConfigListItemAddComp.eventReporter') }}</label>
        <div class="col-8">
          <select
            v-model="cutConfig.startEventReporter"
            :class="['form-control', { 'is-invalid': this.$validator.errors.has('StartEventReporter') }]"
            @change="setStartEventKeyListValues($event)"
          >
            <option
              v-for="(reporter, index) in eventReporters"
              :key="`cutConfigListItemAdd_Option${ index }`"
              :value="reporter"
            >
              {{ reporter }}
            </option>
          </select>
          <span
            v-show="errors.has('StartEventReporter')"
            class="badge badge-danger"
          >{{ errors.first('StartEventReporter') }}</span>
        </div>
      </div>

      <div class="form-group m-form__group row mb-2">
        <label class="col-4 col-form-label">{{ $t('cutConfigListItemAddComp.eventKey') }}</label>
        <div class="col-8">
          <select
            v-model="cutConfig.startEventKey"
            :class="['form-control', { 'is-invalid': this.$validator.errors.has('StartEventKey') }]"
          >
            <option
              v-for="(key, index) in startModifiedEventKeys"
              :key="`cutConfigListItemAdd_Option${ index }`"
              :value="key"
            >
              {{ key }}
            </option>
          </select>
          <span
            v-show="errors.has('StartEventKey')"
            class="badge badge-danger"
          >{{ errors.first('StartEventKey') }}</span>
        </div>
      </div>

      <div class="form-group m-form__group row mb-2">
        <label class="col-4 col-form-label">{{ $t('cutConfigListItemAddComp.eventIndex') }}</label>
        <div class="col-8">
          <input
            v-model="cutConfig.startEventIndex"
            :class="['form-control', { 'is-invalid': this.$validator.errors.has('StartEventIndex') }]"
            min="0"
            type="number"
          >
          <span
            v-show="errors.has('StartEventIndex')"
            class="badge badge-danger"
          >{{ errors.first('StartEventIndex') }}</span>
        </div>
      </div>

      <div class="form-group m-form__group row mb-2">
        <label class="col-4 col-form-label">{{ $t('cutConfigListItemAddComp.offset') }} <small>({{ $t('seconds') }})</small></label>
        <div class="col-8">
          <input
            v-model="cutConfig.startOffset"
            :class="['form-control', { 'is-invalid': this.$validator.errors.has('StartOffset') }]"
            type="number"
          >
          <span
            v-show="errors.has('StartOffset')"
            class="badge badge-danger"
          >{{ errors.first('StartOffset') }}</span>
        </div>
      </div>

      <h5 class="m-0 mt-4">
        {{ $t('end') }}
      </h5>
      <hr class="m-0 mb-3">

      <div class="form-group m-form__group row mb-2">
        <label class="col-4 col-form-label">{{ $t('cutConfigListItemAddComp.eventReporter') }}</label>
        <div class="col-8">
          <select
            v-model="cutConfig.endEventReporter"
            :class="['form-control', { 'is-invalid': this.$validator.errors.has('EndEventReporter') }]"
            @change="setEndEventKeyListValues($event)"
          >
            <option
              v-for="(reporter, index) in eventReporters"
              :key="`cutConfigListItemAdd_Option${ index }`"
              :value="reporter"
            >
              {{ reporter }}
            </option>
          </select>
          <span
            v-show="errors.has('EndEventReporter')"
            class="badge badge-danger"
          >{{ errors.first('EndEventReporter') }}</span>
        </div>
      </div>

      <div class="form-group m-form__group row mb-2">
        <label class="col-4 col-form-label">{{ $t('cutConfigListItemAddComp.eventKey') }}</label>
        <div class="col-8">
          <select
            v-model="cutConfig.endEventKey"
            :class="['form-control', { 'is-invalid': this.$validator.errors.has('EndEventKey') }]"
          >
            <option
              v-for="(key, index) in endModifiedEventKeys"
              :key="`cutConfigListItemAdd_Option${ index }`"
              :value="key"
            >
              {{ key }}
            </option>
          </select>
          <span
            v-show="errors.has('EndEventKey')"
            class="badge badge-danger"
          >{{ errors.first('EndEventKey') }}</span>
        </div>
      </div>

      <div class="form-group m-form__group row mb-2">
        <label class="col-4 col-form-label">{{ $t('cutConfigListItemAddComp.eventIndex') }}</label>
        <div class="col-8">
          <input
            v-model="cutConfig.endEventIndex"
            :class="['form-control', { 'is-invalid': this.$validator.errors.has('EndEventIndex') }]"
            min="0"
            type="number"
          >
          <span
            v-show="errors.has('EndEventIndex')"
            class="badge badge-danger"
          >{{ errors.first('EndEventIndex') }}</span>
        </div>
      </div>

      <div class="form-group m-form__group row mb-2">
        <label class="col-4 col-form-label">{{ $t('cutConfigListItemAddComp.offset') }} <small>({{ $t('seconds') }})</small></label>
        <div class="col-8">
          <input
            v-model="cutConfig.endOffset"
            :class="['form-control', { 'is-invalid': this.$validator.errors.has('EndOffset') }]"
            type="number"
          >
          <span
            v-show="errors.has('EndOffset')"
            class="badge badge-danger"
          >{{ errors.first('EndOffset') }}</span>
        </div>
      </div>
      <template
        v-if="cutConfig.configType == `Video`"
      >
        <h5 class="m-0 mt-4">
          Transition Type
        </h5>
        <hr class="m-0 mb-3">
        <div class="form-group m-form__group row mb-2">
          <label class="col-4 col-form-label">{{ $t('type') }}</label>
          <div class="col-8">
            <select
              v-model="cutConfig.transitionConfig.transitionType"
              :class="['form-control', { 'is-invalid': this.$validator.errors.has('TransitionType') }]"
              @change="setDefaultDuration(cutConfig.transitionConfig.transitionType)"
            >
              <option
                v-for="(type, index) in transitionTypes"
                :key="`cutConfigListItemAdd_Option${ index }`"
                :value="type"
              >
                {{ type }}
              </option>
            </select>
            <span
              v-show="errors.has('TransitionType')"
              class="badge badge-danger"
            >{{ errors.first('TransitionType') }}</span>
          </div>
        </div>
        <div class="form-group m-form__group row mb-2">
          <label class="col-4 col-form-label">{{ $t('duration') }}</label>
          <div class="col-8">
            <input
              v-model="cutConfig.transitionConfig.duration"
              :class="['form-control']"
              :readonly="cutConfig.transitionConfig.transitionType == 'None'"
              min="0"
              step="0.25"
              type="number"
            >
          </div>
        </div>
      </template>
     
      <template
        v-if="cutConfig.configType == `pip`"
      >
        <h5 class="m-0 mt-4">
          {{ $t('show') }}
        </h5>
        <hr class="m-0 mb-3">

        <div class="form-group m-form__group row mb-2">
          <label class="col-4 col-form-label">{{ $t('cutConfigListItemAddComp.eventReporter') }}</label>
          <div class="col-8">
            <select
              v-model="cutConfig.showEventReporter"
              :class="['form-control']"
            >
              <option
                v-for="(reporter, index) in eventReporters"
                :key="`cutConfigListItemAdd_Option${ index }`"
                :value="reporter"
              >
                {{ reporter }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group m-form__group row mb-2">
          <label class="col-4 col-form-label">{{ $t('cutConfigListItemAddComp.eventKey') }}</label>
          <div class="col-8">
            <select
              v-model="cutConfig.showEventKey"
              :class="['form-control']"
            >
              <option
                v-for="(key, index) in eventKeys"
                :key="`cutConfigListItemAdd_Option${ index }`"
                :value="key"
              >
                {{ key }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group m-form__group row mb-2">
          <label class="col-4 col-form-label">{{ $t('cutConfigListItemAddComp.eventIndex') }}</label>
          <div class="col-8">
            <input
              v-model="cutConfig.showEventIndex"
              :class="['form-control']"
              min="0"
              type="number"
            >
          </div>
        </div>

        <div class="form-group m-form__group row mb-2">
          <label class="col-4 col-form-label">{{ $t('cutConfigListItemAddComp.offset') }} <small>({{ $t('seconds') }})</small></label>
          <div class="col-8">
            <input
              v-model="cutConfig.showOffset"
              :class="['form-control']"
              type="number"
            >
          </div>
        </div>
      </template>

      <button
        class="btn btn-sm btn-primary float-right"
        @click.prevent="addCutConfig()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="plus"
        />
        <span>{{ $t('add') }}</span>
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "CutConfigListItemAdd",
  mixins: [
    errorMixin
  ],
  props: {
    cutConfigId: {
      type: Number,
      required: true
    },
    sourceNames: {
      type: Array,
      required: true
    },
    configTypes: {
      type: Array,
      required: true
    },
    eventKeys: {
      type: Array,
      required: true
    },
    eventReporters: {
      type: Array,
      required: true
    },
    transitionTypes: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      cutConfig: {
        configType: 'Video',
        name: 'New Cut Config',
        sourceName: 'Unknown',
        endEventReporter: 'Unknown',
        endEventKey: 'Unknown',
        endEventIndex: 0,
        endOffset: 0,
        startEventReporter: 'Unknown',
        startEventKey: 'Unknown',
        startEventIndex: 0,
        startOffset: 0,
        showEventReporter: 'Unknown',
        showEventKey: 'Unknown',
        showEventIndex: 0,
        showOffset: 0,
        transitionConfig:{
          transitionType: 'Crossfade',
          duration: 0.25
        }
      },
      startFinishEventKeys: [
        'SessionStarted', 
        'SessionFinished', 
        'RaceStarted', 
        'RaceFinished', 
        'RaceAborted'
      ],
      startModifiedEventKeys: null,
      endModifiedEventKeys: null
    }
  },
  created () {
    this.setStartEventKey(this.cutConfig.startEventReporter);
    this.setEndEventKey(this.cutConfig.endEventReporter);
  },
  methods: {
    toCamelCase (str) {
      return str[0].toLowerCase() + str.slice(1);
    },
    setDefaultDuration (type) {
      switch(type) {
        case 'None':
          this.cutConfig.transitionConfig.duration = null;
          break;
        case 'Crossfade':
          this.cutConfig.transitionConfig.duration = 0.25;
          break;
        case 'PipFadeInBottomLeft':
          this.cutConfig.transitionConfig.duration = 4;
          break;
        case 'PipFadeInBottomRight':
          this.cutConfig.transitionConfig.duration = 4;
          break;
        default:
          this.cutConfig.transitionConfig.duration = null;
      }
    },
    addCutConfig () {
      this.axios.post(`/VideoPostprocessing/AddCutConfig`, this.cutConfig)
        .then(() => {
          this.$emit('cutConfigListItemEdit_refreshCutConfigList');
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
    setStartEventKeyListValues (event) {
      var val = event.target.value;
      this.setStartEventKey(val);
      this.cutConfig.startEventKey = this.startModifiedEventKeys.find(x => x);
    },
    setEndEventKeyListValues (event) {
      var val = event.target.value;
      this.setEndEventKey(val);
      this.cutConfig.endEventKey = this.endModifiedEventKeys.find(x => x);
    },
    setStartEventKey (val) {
      if (val === "StartFinish") {
          this.startModifiedEventKeys = this.eventKeys.filter(x => this.startFinishEventKeys.includes(x) && x != "Unknown");
      } else if (val === "VideoRecorder") {
          this.startModifiedEventKeys = this.eventKeys.filter(x => !this.startFinishEventKeys.includes(x) && x != "Unknown");
      } else {
          this.startModifiedEventKeys = this.eventKeys.filter(x => x == "Unknown");
      }
    },
    setEndEventKey (val) {
      if (val === "StartFinish") {
          this.endModifiedEventKeys = this.eventKeys.filter(x => this.startFinishEventKeys.includes(x) && x != "Unknown");
      } else if (val === "VideoRecorder") {
          this.endModifiedEventKeys = this.eventKeys.filter(x => !this.startFinishEventKeys.includes(x) && x != "Unknown");
      } else {
          this.endModifiedEventKeys = this.eventKeys.filter(x => x == "Unknown");
      }
    }
  }
}
</script>
